/* eslint-disable no-console */
/**
 * @description 自定义事件，类似document上面的onclick ，监听动作和执行函数。 -观察者模式 代码解耦
 * @version 0.0.1
 * @author 18064986
 */
class Event {
  constructor() {
    this._instance = null;
    // 缓存所有事件对象和对应方法 {'click':[{func,context},{func,context},{func,context}]}
    this._event = {};
  }

  // 监听事件,返回this指向
  on(key, func, context = this) {
    if (key in this._event && Array.isArray(this._event[key])) {
      this._event[key].push({ func, context });
    } else {
      this._event[key] = [{ func, context }];
    }
  }

  // 触发事件
  emit(key, data) {
    try {
      let target = this._event[key];
      if (target) {
        let count = target.length;
        for (var i = 0; i < count; i++) {
          target[i]["func"].call(target[i]["context"], data);
        }
        return true;
      }
    } catch (e) {
      console.error("-->", e);
      return false;
    }
  }

  // 删除事件
  remove(type, func) {
    try {
      let target = this._event[type];
      let index = target.indexOf(func);
      if (index === -1) {
        console.warn("事件不存在");
      } else {
        target.splice(index, 1);
      }
    } catch (e) {
      console.error("阿弥陀佛,施主回头是岸");
    }
  }

  // 触发一次
  once(type, func) {
    // eslint-disable-next-line no-unused-expressions
    this.emit(type, func) ? this.remove(type, func) : null;
  }

  static instance() {
    if (!this._instance) {
      return new Event();
    }
    return this._instance;
  }
}

let event = Event.instance();

export { event as Event };
