<template>
  <div class="page-home pos-r">
    <background-video :video="video"></background-video>

    <article class="page-cont pos-r">
      <div class="aside">
        <div class="title" v-html="product.name"></div>
      </div>

      <div class="cont" :class="{ active: active == true }">
        <div class="pos-r cont-wrap">
          <!-- 产品介绍 -->
          <div v-if="status && product.intro">
            <div class="scl">
              <header class="cont-header">
                <p class="mb-20" v-html="product.intro"></p>
              </header>

              <div class="pt-34 pb-34">
                <div class="flex mb-35" v-if="product.simplifyOperation">
                  <div class="mr-6 mt-8">
                    <i class="ico-42 mt-8 ico-4"></i>
                  </div>
                  <div class="f-20">
                    <div class="bold mb8">
                      {{ $t("article.jianHuaCaoZuo") }}
                    </div>
                    <div
                      class="c-666 mt-6"
                      v-html="product.simplifyOperation"
                    ></div>
                  </div>
                </div>

                <div class="flex mb-35" v-if="product.increaseOfEfficiency">
                  <div class="mr-6 mt-8">
                    <i class="ico-42 ico-5"></i>
                  </div>
                  <div class="f-20">
                    <div class="bold mb8">{{ $t("article.tiGaoXiaoLu") }}</div>
                    <div
                      class="c-666 mt-6"
                      v-html="product.increaseOfEfficiency"
                    ></div>
                  </div>
                </div>

                <div class="flex mb-35" v-if="product.improveQuality">
                  <div class="mr-6 mt-8">
                    <i class="ico-42 ico-6"></i>
                  </div>
                  <div class="f-20">
                    <div class="bold mb8">
                      {{ $t("article.gaiShanZhiLiang") }}
                    </div>
                    <div
                      class="c-666 mt-6"
                      v-html="product.improveQuality"
                    ></div>
                  </div>
                </div>

                <div class="flex mb-35" v-if="product.improveFlexibility">
                  <div class="mr-6 mt-8">
                    <i class="ico-42 ico-7"></i>
                  </div>
                  <div class="f-20">
                    <div class="bold mb8">
                      {{ $t("article.tiShenRouXing") }}
                    </div>
                    <div
                      class="c-666 mt-6"
                      v-html="product.improveFlexibility"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="status == false && list.length > 0" class="lh-30">
            <div class="tab flex" v-if="list.length > 1">
              <div
                v-for="(item, index) of list"
                :key="index"
                class="tab-item"
                :class="{ active: index == cur }"
                @click="tabchange(index)"
              >
                {{ item.model }}
              </div>
            </div>
            <div>
              <div class="scl">
                <div
                  v-for="(item, index) of list"
                  :key="index"
                  v-show="index == cur"
                >
                  <p class="f-18 bold">{{ $t("article.chanPinXinHao") }}</p>
                  <p class="f-18 c-666" v-html="item.model"></p>
                  <p class="f-18 bold">{{ $t("article.fuZhong") }}</p>
                  <p class="f-18 c-666" v-html="item.load"></p>
                  <p class="f-18 bold">{{ $t("article.gongZuoFanWei") }}</p>
                  <p class="f-18 c-666" v-html="item.workingRange"></p>
                  <p class="f-18 bold">
                    {{ $t("article.chongFuDingWeiJinDu") }}
                  </p>
                  <p class="f-18 c-666" v-html="item.accuracy"></p>
                  <p class="f-18 bold">{{ $t("article.anZhuangFangShi") }}</p>
                  <p class="f-18 c-666" v-html="item.installationMode"></p>
                  <p class="f-18 bold">{{ $t("article.fangHuDengJi") }}</p>
                  <p class="f-18 c-666" v-html="item.degreeOfProtection"></p>
                  <p class="f-18 bold">{{ $t("article.zhouShu") }}</p>
                  <p class="f-18 c-666" v-html="item.numberOfAxes"></p>
                  <p class="f-18 bold">{{ $t("article.kongZhiShu") }}</p>
                  <p class="f-18 c-666" v-html="item.controller"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-back" @click="$router.go(-1)">
          <span class="ico-back"></span>
          {{ $t("article.fanHui") }}
        </div>

        <div class="aside-nav pos-a" @click="onoff"></div>
      </div>
    </article>
  </div>
</template>

<script>
import BackgroundVideo from "@/components/BackgroundVideo";
import { detail } from "../api/index.js";
import langMinix from "../common/lang";
// import { lang } from "../utils/utils";
import { Event } from "../utils/Event.js";

export default {
  name: "Article",
  components: { BackgroundVideo },
  data: () => {
    return {
      status: false,
      product: {},
      video: "",
      active: false,
      list: [],
      cur: 0,
    };
  },
  created() {
    this.status = this.$route.query.ch === "itr";

    this.detail();
  },
  mounted() {
    Event.on("lang", this.detail);
  },
  minixs: [langMinix],
  methods: {
    onoff() {
      this.active = !this.active;
    },
    async detail() {
      try {
        const id = this.$route.query.id;
        let res = await detail(id);
        if (res.code === 0 && res.data) {
          let _res = JSON.parse(
            JSON.stringify(res).replace(/(\\n|\\r)/g, "<br/>")
          );
          console.log("_res.data.video", _res.data.video);
          this.product = _res.data;
          this.video = _res.data.video;
          this.list = _res.data.model || [];
        }
      } catch (error) {
        console.log(error);
      }
    },

    tabchange(index) {
      this.cur = index;
    },
  },
};
</script>
<style lang="less" scoped>
.menu {
  position: absolute;

  dt {
    height: 42px;
    background: #000000;
    border-radius: 5px 5px 0px 0px;
    font-size: 22px;
    font-family: ABB;
    font-weight: bold;
    color: #ffffff;
    line-height: 42px;
    padding-left: 29px;
  }

  dd ul li {
    height: 42px;
    line-height: 42px;
    background: rgba(242, 242, 242, 0.95);
    border-bottom: 1px solid #e0e0e0;
    font-size: 16px;
    font-family: ABB;
    font-weight: 400;
    padding-left: 28px;
    position: relative;

    a {
      color: #1a1a1a;
      display: block;

      ul {
        width: 380px;
        position: absolute;
        left: 300px;
        top: 0px;
        border-radius: 5px;
        display: none;
        padding-left: 20px;
      }
    }

    a:hover {
      color: #ff000f;

      ul {
        display: block;
      }
    }
  }

  dd ul li:last-child {
    border-bottom: none;
  }
}

.ico-hand {
  width: 60px;
  height: 57px;
  cursor: pointer;
  background: url("../assets/img/ico-hand.png") no-repeat;
  animation: suofang 2s linear infinite;
}

.page-cont {
  width: 1600px;
  height: 900px;

  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  .page-cont {
    width: 1200px;
    //height: 675px;
  }
}

.aside {
  width: 458px;
  height: 900px;
  padding-left: 65px;
  padding-top: 68px;
  overflow: hidden;
}

.title {
  line-height: 36px;
  font-size: 32px;
  font-family: ABB;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 145px;
  position: relative;
  padding-top: 20px;
}

.title::after {
  display: inline-block;
  width: 34px;
  height: 4px;
  background: #ff0000;
  content: "";
  position: absolute;
  top: -2px;
  left: 0;
}

.cont {
  position: absolute;
  top: 83px;
  right: 160px;
  margin-right: 50px;
  width: 420px;
  height: 675px;
  background: rgba(255, 255, 255, 0.9);
  opacity: 0.9;
  border-radius: 0px 0px 5px 5px;
  padding: 32px 20px;
  animation: moveani1 0.2s linear forwards;
  .aside-nav {
    width: 33px;
    height: 51px;
    left: -33px;
    top: 300px;
    cursor: pointer;
    background: url("../assets/img/ico-arrow-ra.png") no-repeat;
  }

  .cont-wrap {
    width: 100%;
    height: 560px;

    .scl {
      width: 100%;
      height: 560px;
      overflow-y: auto;
    }
  }

  .cont-header {
    font-size: 20px;
    font-family: ABB;
    font-weight: bold;
    color: #000000;
    line-height: 32px;
    border-bottom: 1px solid #ddd;
  }

  .mb8 {
    margin-bottom: -8px;
  }

  .ico-42 {
    width: 42px;
    height: 42px;
    display: inline-block;
  }

  .ico-4 {
    background: url("../assets/img/ico-4.png") no-repeat;
  }

  .ico-5 {
    background: url("../assets/img/ico-5.png") no-repeat;
  }
}

.cont.active {
  margin-right: 0;
  animation: moveani 0.2s linear forwards;
  .aside-nav {
    background: url("../assets/img/ico-arrow-r.png") no-repeat;
  }
}

@keyframes moveani {
  0% {
    right: 0px;
  }
  100% {
    right: -419px;
  }
}

@keyframes moveani1 {
  0% {
    right: -419px;
  }
  100% {
    right: 0;
  }
}
.ico-6 {
  background: url("../assets/img/ico-6.png") no-repeat;
}

.ico-7 {
  background: url("../assets/img/ico-7.png") no-repeat;
}

.btn-back {
  cursor: pointer;
  width: 120px;
  height: 42px;
  background: #ff000f;
  border-radius: 21px;
  line-height: 42px;
  margin-left: 220px;
  font-size: 20px;
  text-align: center;
  font-family: ABB;
  font-weight: 400;
  color: #ffffff;
}

.ico-back {
  width: 20px;
  height: 20px;
  background: url("../assets/img/ico-back.png") no-repeat;
  background-size: 20px 20px;
  position: relative;
  top: 3px;
}

.tab {
  height: 44px;
  width: 318px;
  position: absolute;
  top: 105px;
  right: -201px;
  transform: rotate(90deg);
}

.tab-item {
  padding: 0 20px;
  height: 44px;
  line-height: 44px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  background-color: #a7a7a7;
  color: #fff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  cursor: pointer;
}

.tab-item.active {
  background-color: #ff000f;
}
</style>
