<template>
  <div class="video-wrap">
    <video class="video" autoplay loop ref="video">
      <source :src="delVideo" type="video/mp4" />
    </video>
  </div>
</template>

<script>
export default {
  props: {
    video: {
      default: "",
      type: String,
    },
    poster: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      delVideo: "",
    };
  },
  watch: {
    video() {
      this.load();
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      if (this.video != "") {
        if (this.video.includes(",")) {
          this.delVideo = this.video.split(",")[0];
        } else {
          this.delVideo = this.video;
        }
      } else {
        this.delVideo =
          "https://abbr.oss-cn-beijing.aliyuncs.com/p/358xESxgrcBFk7an.mp4";
      }
      console.log("xxxx", this.delVideo);
      this.$refs.video.load();
    },
  },
};
</script>

<style lang="css" scoped>
.video-wrap {
  width: 1600px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.video {
  position: absolute;
  width: 1600px;
  height: 900px;
  /* background: url("../assets/img/bg1.jpg?v=1632311475056");
  background-size: cover; */
}

@media screen and (max-width: 1200px) {
  .video-wrap {
    width: 1200px;
  }

  .video {
    width: 1200px;
    height: auto;
  }
}
</style>
